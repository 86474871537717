<template>
  <div>
    <v-container fluid>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Reprehenderit velit sequi assumenda nisi porro ratione
        nesciunt? Ducimus sint eum amet dignissimos autem maxime
        veniam aut adipisci iste perferendis, dolore est? Lorem ipsum
        dolor sit amet consectetur adipisicing elit. Sit cum magnam,
        voluptate iure amet quidem vitae dolor corporis, voluptas
        fuga, animi illum ipsam commodi iste quaerat error voluptatem
        facere qui?
      </p>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
